<template>
  <gf-base>
    <gf-card v-loading="loading" :subtitle="subtitle" :title="title">
      <template #toolbar>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <el-dialog v-loading="addProductModalLoading" :close-on-click-modal="false" :visible.sync="addProductModalVisible" append-to-body title="Add Product">
        <gf-form>
          <el-form ref="product" :model="product" :rules="productRules" label-width="130px" size="small">
            <el-form-item v-if="addProductPage === 1">
              <el-radio-group v-model="createProductMode" size="small">
                <el-radio-button label="New Variant"></el-radio-button>
                <el-radio-button label="New Pack"></el-radio-button>
              </el-radio-group>
            </el-form-item>
            <div v-if="addProductPage === 1 && createProductMode === 'New Variant'">
              <el-form-item class="mb-2">
                <el-checkbox v-model="existingProduct">Existing Product</el-checkbox>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: 'Product Name is require', trigger: 'blur' }" label="Product Name" prop="name">
                <div v-if="existingProduct">
                  <el-select v-if="product.name === null || product.name === ''" v-model="productId" :remote-method="searchProduct" filterable popper-class="gf-select-product" remote style="width: 100%" @change="selectProduct">
                    <el-option v-for="product in products" :key="product.id" :label="product.name" :value="product.id">
                      <div class="gf-select-product__name">{{ product.name }}</div>
                      <div class="gf-select-product__sub">
                        <span class="gf-select-product__sub__sku">SKU: {{ product.sku }}</span>
                      </div>
                    </el-option>
                  </el-select>
                  <div v-else>
                    <span class="font-weight-normal">{{ product.name }} <i class="el-icon-close text-hover-primary ml-2" style="cursor: pointer;" @click="removeSelectedProduct"></i></span>
                  </div>
                </div>
                <el-input v-else v-model="product.name"></el-input>
              </el-form-item>
              <el-form-item v-if="!existingProduct" label="Units" prop="units">
                <el-input v-model="product.units"></el-input>
              </el-form-item>
              <div v-if="existingProduct" class="multiple-column">
                <el-form-item class="flex-grow mr-2" label="Variant Name" prop="variant_name">
                  <el-input v-model="product.variant_name" :disabled="autoGenerateVariant"></el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-checkbox v-model="autoGenerateVariant">Auto</el-checkbox>
                </el-form-item>
              </div>
              <div class="multiple-column">
                <el-form-item class="flex-grow mr-2" label="SKU">
                  <el-input v-model="product.sku" :disabled="autoGenerateSKU"></el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-checkbox v-model="autoGenerateSKU">Auto</el-checkbox>
                </el-form-item>
              </div>
              <div v-if="existingProduct && selectedProduct">
                <el-form-item v-for="(option, index) in product.options" :key="index" :label="selectedProduct.options[index].name" :prop="`options.${index}.value`" :rules="{required: true, message: 'Option Name is require'}">
                  <el-input v-model="option.value" @input="generateSKU"></el-input>
                </el-form-item>
              </div>
              <el-form-item label="Buy Price">
                <el-input v-model="prices.buy_price">
                  <template slot="prepend">₱</template>
                </el-input>
              </el-form-item>
              <el-form-item label="Wholesale Price">
                <el-input v-model="prices.wholesale_price">
                  <template slot="prepend">₱</template>
                </el-input>
              </el-form-item>
              <el-form-item :class="{ 'mb-2': !existingProduct }" label="Retail Price">
                <el-input v-model="prices.retail_price">
                  <template slot="prepend">₱</template>
                </el-input>
              </el-form-item>
              <div v-if="!existingProduct" class="multiple-column">
                <el-form-item>
                  <el-checkbox v-model="withOption">With Options</el-checkbox>
                </el-form-item>
                <el-form-item label-width="40px">
                  <el-checkbox v-model="withPack">With Pack</el-checkbox>
                </el-form-item>
              </div>
            </div>
            <div v-if="addProductPage === 1 && createProductMode === 'New Pack'">
              <el-form-item :rules="{ required: true, message: 'Variant Name is require', trigger: 'blur' }" label="Variant Name" prop="name">
                <el-select v-if="product.name === null || product.name === ''" v-model="variantId" :remote-method="searchVariant" filterable popper-class="gf-select-product" remote style="width: 100%" @change="selectVariant">
                  <el-option v-for="variant in variants.filter(f => f.is_pack === 0)" :key="variant.id" :label="variant.name" :value="variant.id">
                    <div class="gf-select-product__name">{{ variant.product.name }} - {{ variant.name }}</div>
                    <div class="gf-select-product__sub">
                      <span class="gf-select-product__sub__sku">SKU: {{ variant.sku }}</span>
                    </div>
                  </el-option>
                </el-select>
                <div v-else>
                  <span class="font-weight-normal">{{ product.name }} <i class="el-icon-close text-hover-primary ml-2" style="cursor: pointer;" @click="removeSelectedProduct"></i></span>
                </div>
              </el-form-item>
              <el-form-item label="Pack Name" prop="pack.label">
                <el-input v-model="product.pack.label"></el-input>
              </el-form-item>
              <el-form-item label="Pack Size" prop="pack.size">
                <el-input v-model="product.pack.size"></el-input>
              </el-form-item>
              <el-form-item label="Buy Price">
                <el-input v-model="prices.buy_price">
                  <template slot="prepend">₱</template>
                </el-input>
              </el-form-item>
              <el-form-item label="Wholesale Price">
                <el-input v-model="prices.wholesale_price">
                  <template slot="prepend">₱</template>
                </el-input>
              </el-form-item>
              <el-form-item :class="{ 'mb-2': !existingProduct }" label="Retail Price">
                <el-input v-model="prices.retail_price">
                  <template slot="prepend">₱</template>
                </el-input>
              </el-form-item>
            </div>
            <div v-if="addProductPage === 2">
              <div v-if="withOption" class="mb-5">
                <span class="font-weight-bold font-size-mb text-dark">Variant Options</span>
                <div style="display: flex; justify-content: space-between;">
                  <el-form-item class="mr-5" label-width="0" style="margin: 0; width: 145px">
                    Option Name
                  </el-form-item>
                  <el-form-item label-width="0" style="margin: 0; flex-grow: 1">
                    Option Value
                  </el-form-item>
                </div>
                <div v-for="(option, index) in product.options" :key="index" style="display: flex;">
                  <el-form-item :prop="`options.${index}.name`" :rules="{ required: true, message: 'Option Name is require', trigger: 'blur' }" class="mr-5" label-width="0" style="width: 145px">
                    <el-input v-model="option.name" style="width: 100%"></el-input>
                  </el-form-item>
                  <el-form-item :prop="`options.${index}.value`" :rules="{ required: true, message: 'Option Value is require', trigger: 'blur' }" label-width="0" style="flex-grow: 1">
                    <el-input v-model="option.value"></el-input>
                  </el-form-item>
                  <el-form-item v-if="product.options.length > 1 && index === product.options.length - 1" class="ml-4" label-width="0">
                    <el-button icon="el-icon-delete" size="small" type="danger" @click="removeOption"></el-button>
                  </el-form-item>
                </div>
                <gf-button size="sm" type="primary" @click="addOption">
                  Add Option
                </gf-button>
              </div>
              <div v-if="withPack" class="mb-5">
                <span class="font-weight-bold font-size-mb text-dark">Pack</span>
                <div style="display: flex; justify-content: space-between;">
                  <el-form-item class="mr-5" label-width="0" style="margin: 0; width: 145px">
                    Pack Label
                  </el-form-item>
                  <el-form-item label-width="0" style="margin: 0;flex-grow: 1">
                    Pack Quantity
                  </el-form-item>
                  <el-form-item class="ml-15" label-width="0">
                  </el-form-item>
                </div>
                <div v-for="(pack, index) in product.packs" :key="index" style="display: flex;">
                  <el-form-item :prop="`packs.${index}.label`" :rules="{ required: true, message: 'Pack Label is require', trigger: 'blur' }" class="mr-5" label-width="0" style="width: 145px">
                    <el-input v-model="pack.label" style="width: 100%"></el-input>
                  </el-form-item>
                  <el-form-item :prop="`packs.${index}.size`" :rules="{ required: true, message: 'Pack Size is require', trigger: 'blur' }" label-width="0" style="flex-grow: 1">
                    <el-input v-model="pack.size"></el-input>
                  </el-form-item>
                  <el-form-item v-if="product.packs.length > 1 && index === product.packs.length - 1" class="ml-4" label-width="0">
                    <el-button icon="el-icon-delete" size="small" type="danger" @click="removePack"></el-button>
                  </el-form-item>
                </div>
                <gf-button size="sm" type="primary" @click="addPack">
                  Add Pack
                </gf-button>
              </div>
            </div>
          </el-form>
          <template #footer>
            <gf-button type="secondary" @click="addProductModalVisible = false">
              <template slot="icon">
                <span class="svg-icon svg-icon-sm">
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                        <rect height="2" rx="1" width="16" x="0" y="7"/>
                        <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                      </g>
                    </g>
                  </svg>
                </span>
              </template>
              Close
            </gf-button>
            <gf-button v-if="addProductPage > 1" type="primary" @click="addProductPage--">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <polygon points="0 0 24 0 24 24 0 24"/>
                      <rect fill="#000000" height="14" opacity="0.3" rx="1" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) " width="2" x="11" y="5"/>
                      <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "/>
                    </g>
                  </svg>
                </span>
              </template>
              Prev
            </gf-button>
            <gf-button v-if="(withPack || withOption) && createProductMode === 'New Variant' && addProductPage === 1" type="primary" @click="nextPageProduct(2)">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <polygon points="0 0 24 0 24 24 0 24"/>
                      <rect fill="#000000" height="14" opacity="0.3" rx="1" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " width="2" x="11" y="5"/>
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "/>
                    </g>
                  </svg>
                </span>
              </template>
              Next
            </gf-button>
            <gf-button v-if="(!withPack && !withOption) || createProductMode === 'New Pack' || addProductPage === 2" type="primary" @click="addProduct">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <polygon points="0 0 24 0 24 24 0 24"/>
                      <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                      <rect fill="#000000" height="5" opacity="0.3" rx="0.5" width="3" x="12" y="4"/>
                    </g>
                  </svg>
                </span>
              </template>
              Save
            </gf-button>
          </template>
        </gf-form>
      </el-dialog>
      <el-dialog v-loading="addSupplierModalLoading" :close-on-click-modal="false" :visible.sync="addSupplierModalVisible" append-to-body title="Add Supplier">
        <gf-form>
          <el-form ref="supplier" :model="supplier" :rules="supplierRules" label-width="130px" size="small">
            <el-form-item>
              <span class="font-weight-normal text-dark">Supplier Detail</span>
            </el-form-item>
            <el-form-item label="Supplier Name" prop="name">
              <el-input v-model="supplier.name"></el-input>
            </el-form-item>
            <el-form-item label="Code" prop="code">
              <el-input v-model="supplier.code"></el-input>
            </el-form-item>
            <el-form-item label="Phone Number" prop="phone_number">
              <el-input v-model="supplier.phone_number"></el-input>
            </el-form-item>
            <el-form-item>
              <span class="font-weight-normal text-dark">Address</span>
            </el-form-item>
            <el-form-item :rules="{ required: true, message: 'Address Line 1 is require' }" label="Address Line 1" prop="address.0.address_line_1">
              <el-input v-model="supplier.address[0].address_line_1"></el-input>
            </el-form-item>
            <el-form-item label="Address Line 2" prop="address.0.address_line_2">
              <el-input v-model="supplier.address[0].address_line_2"></el-input>
            </el-form-item>
            <el-form-item label="City" prop="address.0.city">
              <el-input v-model="supplier.address[0].city"></el-input>
            </el-form-item>
            <el-form-item label="Zip Code" prop="address.0.zip_code">
              <el-input v-model="supplier.address[0].zip_code"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <gf-button type="secondary" @click="addSupplierModalVisible = false">
              <template slot="icon">
                <span class="svg-icon svg-icon-sm">
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                        <rect height="2" rx="1" width="16" x="0" y="7"/>
                        <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                      </g>
                    </g>
                  </svg>
                </span>
              </template>
              Close
            </gf-button>
            <gf-button type="primary" @click="addSupplier">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <polygon points="0 0 24 0 24 24 0 24"/>
                      <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                      <rect fill="#000000" height="5" opacity="0.3" rx="0.5" width="3" x="12" y="4"/>
                    </g>
                  </svg>
                </span>
              </template>
              Save
            </gf-button>
          </template>
        </gf-form>
      </el-dialog>
      <el-form ref="po" :model="po" :rules="poRules" label-width="110px">
        <gf-content overflow>
          <template #title>
            <div class="gf-title">
              <div v-if="selectedSupplier.name" class="supplier-name"><p>{{ selectedSupplier.name }} <i class="el-icon-close" @click="removeSelectedSupplier"></i></p> </div>
              <div v-else class="company-select">
                <el-form-item label-width="0" prop="supplier_id">
                  <el-select v-model="po.supplier_id" :remote-method="searchSupplier" filterable placeholder="Select Supplier" remote size="medium" @change="changeSelectedSupplier">
                    <el-option v-for="supplier in suppliers" :key="supplier.id" :label="supplier.name" :value="supplier.id"></el-option>
                  </el-select>
                </el-form-item>
                <span> or </span>
                <el-button type="text" @click="addSupplierModalVisible=true">create a new supplier</el-button>
              </div>
            </div>
          </template>
          <template #toolbar>
            <div class="gf-toolbar">
              <div class="number">
                <p>#{{ po.po_number }}</p>
              </div>
            </div>
          </template>
          <template #header>
            <el-row>
              <el-col :lg="8" :md="12" :sm="24">
                <el-form-item label="Address" prop="supplier_address_id">
                  <el-select v-model="po.supplier_address_id" placeholder="Select" size="small" style="width: 80%">
                    <el-option v-for="addr in supplier_addresses" :key="addr.id" :label="addr.label" :value="addr.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Reference">
                  <el-input v-model="po.reference" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="Email">
                  <el-input v-model="po.email" size="small" style="width: 80%"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12" :sm="24">
                <el-form-item label="Issued Date">
                  <el-date-picker v-model="po.issue_date" format="dd MMM yyyy" size="small" style="width: 80%" type="date" value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Stock Date">
                  <el-date-picker v-model="po.stock_due" format="dd MMM yyyy" size="small" style="width: 80%" type="date" value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Payment Due">
                  <el-date-picker v-model="po.payment_due" format="dd MMM yyyy" size="small" style="width: 80%" type="date" value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12" :sm="24">
                <el-form-item label="Price List">
                  <el-select v-model="po.price_list_id" placeholder="Select" size="small" style="width: 80%">
                    <el-option v-for="pl in priceLists" :key="pl.id" :label="pl.name" :value="pl.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Totals are">
                  <el-select v-model="po.is_inclusive" placeholder="Select" size="small" style="width: 80%">
                    <el-option :value="0" label="Tax Exclusive"></el-option>
                    <el-option :value="1" label="Tax Inclusive"></el-option>
                    <el-option :value="2" label="No Tax"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <div class="table">
            <gf-table :data="po.items" :scrollable="false" class="mr-4">
              <el-table-column label="Item Name" min-width="200">
                <template slot-scope="slot">
                  <el-form-item v-if="selectedItems[slot.$index] === undefined" :prop="'items.' + slot.$index + '.variant_id'" :rules="{ required: true, message: ' ', trigger: 'change' }" label-width="0">
                    <el-select v-model="slot.row.variant_id" :remote-method="searchVariant" filterable placeholder="Start typing SKU or Name" popper-class="gf-select-product" remote size="mini" style="width: 100%" @change="changeSelectedItem($event, slot.$index)">
                      <el-option v-for="variant in variants" :key="variant.id" :label="variant.name" :value="variant.id">
                        <div v-if="variant.product.name === variant.name && variant.is_pack === 0" class="gf-select-product__name">{{ variant.name }}</div>
                        <div v-else-if="variant.is_pack === 0" class="gf-select-product__name">{{ variant.product.name }}  - {{ variant.name }}</div>
                        <div v-else class="gf-select-product__name">
                          <span v-if="variant.product.name === variant.variant.name">{{ variant.variant.name }} - {{ variant.name }}</span>
                          <span v-else>{{ variant.product.name }} - {{ variant.variant.name }} - {{ variant.name }}</span>
                          <span v-if="variant.is_pack === 1"> (Pack of {{ variant.pack_size }})</span>
                        </div>
                        <div class="gf-select-product__sub">
                          <span class="gf-select-product__sub__sku">SKU: {{ variant.sku }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <div v-else class="gf-item">
                    <div v-if="selectedItems[slot.$index].product.name === selectedItems[slot.$index].name && selectedItems[slot.$index].is_pack === 0" class="gf-item__name">{{ selectedItems[slot.$index].name }}</div>
                    <div v-else-if="selectedItems[slot.$index].is_pack === 0" class="gf-item__name">{{ selectedItems[slot.$index].product.name }} - {{ selectedItems[slot.$index].name }}</div>
                    <div v-else class="gf-item__name">
                      <span v-if="selectedItems[slot.$index].product.name === selectedItems[slot.$index].variant.name">{{ selectedItems[slot.$index].variant.name }} - {{ selectedItems[slot.$index].name }}</span>
                      <span v-else>{{ selectedItems[slot.$index].product.name }} - {{ selectedItems[slot.$index].variant.name }} - {{ selectedItems[slot.$index].name }}</span>
                      <span>(Pack of {{ selectedItems[slot.$index].pack_size }})</span>
                    </div>
                    <div class="gf-item__sub">
                      <span class="gf-item__sub__sku">SKU: {{ selectedItems[slot.$index].sku }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Quantity" min-width="120">
                <template slot-scope="slot">
                  <el-form-item :prop="'items.' + slot.$index + '.quantity'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                    <el-input v-model="slot.row.quantity" size="mini" style="width: 100%"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="After" min-width="120">
                <template  slot-scope="slot">
                  <el-input :value="$NumberFormat(parseFloat(slot.row.quantity) + parseFloat(slot.row.stock))" readonly size="mini" style="width: 100%"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Cost per unit" min-width="120">
                <template slot-scope="slot">
                  <el-form-item :prop="'items.' + slot.$index + '.cost'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                    <el-input v-model="slot.row.cost" size="mini" style="width: 100%"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column v-if="po.is_inclusive !== 2" label="Tax" min-width="90">
                <template slot-scope="slot">
                  <el-form-item :prop="'items.' + slot.$index + '.tax'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                    <el-input v-model="slot.row.tax" size="mini" style="width: 100%"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Total" min-width="120">
                <template  slot-scope="slot">
                  <el-input :value="$NumberFormat(slot.row.cost * slot.row.quantity)" readonly size="mini" style="width: 100%"></el-input>
                </template>
              </el-table-column>
              <el-table-column align="center" label="" width="80">
                <template slot-scope="slot">
                  <el-button v-model="slot.row.delete" icon="el-icon-delete" size="mini" type="danger" @click="removeItem(slot.$index)"></el-button>
                </template>
              </el-table-column>
            </gf-table>
          </div>
          <div class="add-item mr-4">
            <gf-button light size="sm" style="width: 100%" type="primary" @click="addItem">+ Add another item</gf-button>
          </div>
          <div class="footer mt-4 mr-4">
            <div class="note">
              <el-input
                v-model="po.message"
                :rows="2"
                placeholder="Message to Supplier"
                type="textarea">
              </el-input>
            </div>
            <div class="gf-summary">
              <ul class="summary">
                <li class="summary__item">
                  <span class="text">Total Units</span>
                  <span class="value">{{ $NumberFormat(totalUnits) }}</span>
                </li>
                <li v-if="po.is_inclusive !== 2" class="summary__item">
                  <span class="text">Subtotal</span>
                  <span class="value">{{ $NumberFormat(subtotal) }}</span>
                </li>
                <div v-if="po.is_inclusive !== 2">
                  <li v-for="(vat, index) in vats" :key="index" class="summary__item">
                    <span class="text">{{ (po.is_inclusive) ? 'Including': 'Plus' }} VAT ({{ vat.rate }}%)</span>
                    <span class="value">{{ $NumberFormat(vat.value) }}</span>
                  </li>
                </div>
                <li class="summary__item">
                  <span class="text">Total</span>
                  <span class="value">{{ $NumberFormat(total) }}</span>
                </li>
              </ul>
            </div>
          </div>
          <template #footer>
            <div class="text-right">
              <gf-button class="mr-3" type="primary" @click="create('approval')">Submit</gf-button>
              <gf-button type="warning" @click="create('draft')">Save As Draft</gf-button>
            </div>
          </template>
        </gf-content>
      </el-form>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.content {
  .el-form-item  {
    width: auto;
    margin: 0;
  }
}

.el-form {
  height: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}

.multiple-column {
  display: flex;

  .flex-grow {
    flex-grow: 1;
  }
}
</style>

<script>
// // Services
import CompanyService from '@/services/v1/Company'
import CompanyAddressService from '@/services/v1/CompanyAddress'
import PriceListService from '@/services/v1/PriceList'
import VariantService from '@/services/v1/Variant'
import ProductService from '@/services/v1/Product'
import ProductVariantService from '@/services/v1/ProductVariant'
import PurchaseOrderService from '@/services/v1/PurchaseOrder'
import PurchaseOrderItemService from '@/services/v1/PurchaseOrderItem'

import { debounce } from 'lodash'

export default {
  data () {
    return {
      purchaseOrderId: this.$route.params.id,

      // ui
      title: '',
      subtitle: '',
      mode: 'create',
      loading: false,

      // main data
      po: {
        po_number: 'PO000000',
        supplier_id: null,
        supplier_address_id: null,
        bill_to_id: 1,
        ship_to_id: 1,
        issue_date: this.$DateISOFormat(new Date().toISOString()),
        stock_due: this.$DateISOFormat(new Date().toISOString()),
        payment_due: this.$DateISOFormat(new Date().toISOString()),
        reference: null,
        email: null,
        price_list_id: 1,
        is_inclusive: 0,
        message: null,
        items: []
      },
      selectedSupplier: {
        name: null
      },
      selectedItems: [],

      // Rules
      poRules: {
        supplier_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        po_number: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        supplier_address_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        bill_to_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        ship_to_id: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      },
      supplierRules: {
        name: [
          { required: true, message: 'Name can\'t be blank', trigger: 'blur' }
        ]
      },

      // variables
      suppliers: [],
      supplier_addresses: [],
      priceLists: [],
      variants: [],
      supplier: {
        name: null,
        type: 3,
        code: null,
        phone_number: null,
        address: [
          {
            label: 'New Address',
            address_line_1: null,
            address_line_2: null,
            city: null,
            zip_code: null
          }
        ]
      },

      // Modal
      addProductModalVisible: false,
      addProductModalLoading: false,
      addSupplierModalVisible: false,
      addSupplierModalLoading: false,

      // Add Product Modal Values
      createProductMode: 'New Variant',
      addProductPage: 1,
      existingProduct: false,
      autoGenerateSKU: true,
      autoGenerateVariant: true,
      withOption: false,
      withPack: false,
      productSearch: null,
      variantSearch: null,
      selectedProduct: null,
      selectedVariant: null,
      products: [],
      productId: null,
      variantId: null,
      product: {
        name: null,
        variant_name: null,
        units: null,
        sku: null,
        options: [
          { name: null, value: null }
        ],
        packs: [
          { label: null, size: null }
        ],
        pack: {
          label: null,
          size: null
        }
      },
      prices: {
        buy_price: null,
        retail_price: null,
        wholesale_price: null
      },
      productRules: {
        variant_name: [
          { required: true, message: 'Variant Name is require' }
        ],
        units: [
          { required: true, message: 'Units is require' }
        ],
        'pack.label': [
          { required: true, message: 'Pack Label is require' }
        ],
        'pack.size': [
          { required: true, message: 'Pack Size is require' }
        ]
      },

      // filters
      supplierFilter: {
        'cus.type': {
          '=': 3
        }
      },
      variantFilter: {}
    }
  },
  computed: {
    totalUnits () {
      let total = 0

      for (let i = 0; i < this.po.items.length; i++) {
        const e = this.po.items[i]
        total += parseFloat(e.quantity)
      }

      return total
    },
    subtotal () {
      let total = 0

      if (this.po.is_inclusive === 0) {
        for (let i = 0; i < this.po.items.length; i++) {
          const e = this.po.items[i]
          total += parseFloat(e.quantity) * parseFloat(e.cost)
        }
      } else {
        for (let i = 0; i < this.po.items.length; i++) {
          const e = this.po.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const tax = parseFloat(e.tax) / 100
          total += sub - (sub * tax)
        }
      }

      return total
    },
    vats () {
      const vt = []

      if (this.po.items.length > 0) {
        for (let i = 0; i < this.po.items.length; i++) {
          const e = this.po.items[i]
          const found = vt.find(vat => vat.rate === e.tax)

          if (found) {
            found.value += (e.tax / 100) * (e.quantity * e.cost)
          } else {
            vt.push({
              rate: e.tax,
              value: (e.tax / 100) * (e.quantity * e.cost)
            })
          }
        }
      }

      return vt
    },
    total () {
      let total = 0

      if (this.po.items.length > 0) {
        for (let i = 0; i < this.po.items.length; i++) {
          const e = this.po.items[i]
          total += parseFloat(e.quantity) * parseFloat(e.cost)
        }
      }

      if (!this.po.is_inclusive) {
        const vats = this.vats

        for (let i = 0; i < vats.length; i++) {
          const vat = vats[i]
          total += parseFloat(vat.value)
        }
      }

      return total
    }
  },
  watch: {
    'product.name': debounce(async function () {
      if (!this.product.name || this.product.name === '') return

      try {
        let sku = this.product.name.split(' ').join('').substring(0, 6).toUpperCase()
        const pdService = new ProductService()
        const result = await pdService.getLastSKU(sku)
        const existingSKU = result.data.sku

        if (existingSKU) {
          const number = parseInt(existingSKU.substring(sku.length)) + 1
          sku += this.$Numeral(number).format('00')
        }

        this.product.sku = sku
      } catch (error) {
        this.$Error(error)
      }
    }, 400)
  },
  methods: {
    back () {
      this.$router.push({ name: 'purchase-order-list' })
    },
    addItem () {
      this.po.items.push({ variant_id: null, stock: 0, quantity: 1, cost: 0, tax: 12.00 })
    },
    removeItem (index) {
      this.po.items.splice(index, 1)
      this.selectedItems.splice(index, 1)
    },
    create (status) {
      this.$refs.po.validate()
        .then(async () => {
          try {
            this.loading = true

            if (this.po.items.length === 0) {
              this.$notify.error({
                title: 'Error',
                message: 'Purchase Order must contain at least 1 item'
              })
              return
            }

            const po = this.$_.cloneDeep(this.po)
            this.$Sanitize(po)

            delete po.supplier_address

            for (let i = 0; i < po.items.length; i++) {
              const item = po.items[i]
              delete item.stock
              delete item.stocks
              delete item.variants
              delete item.product
              delete item.created_at
              delete item.updated_at
            }

            po.unit_counts = this.totalUnits
            po.subtotal = this.subtotal
            po.vats = JSON.stringify(this.vats)
            po.total = this.total

            if (this.mode === 'create') {
              const poService = new PurchaseOrderService()

              if (status === 'draft') {
                const result = await poService.create(po)
                this.$message.success(`${this.po.po_number} successfully save as draft`)
                this.$router.push({ name: 'purchase-order-detail', params: { id: result.data.id } })
                return
              }

              if (status === 'approval') {
                const result = await poService.submit(po)
                this.$message.success(`${this.po.po_number} successfully submitted`)
                this.$router.push({ name: 'purchase-order-detail', params: { id: result.data.id } })
                return
              }

              return
            }

            if (this.mode === 'update') {
              const poService = new PurchaseOrderService(this.purchaseOrderId)

              if (status === 'draft') {
                await poService.update(po)
                this.$message.success(`${this.po.po_number} successfully save as draft`)
                this.$router.push({ name: 'purchase-order-detail', params: { id: this.purchaseOrderId } })
                return
              }

              if (status === 'approval') {
                await poService.updateSubmit(po)
                this.$message.success(`${this.po.po_number} successfully submitted`)
                this.$router.push({ name: 'purchase-order-detail', params: { id: this.purchaseOrderId } })
              }
            }
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },

    // add product modal
    addProduct () {
      this.$refs.product.validate()
        .then(async () => {
          try {
            this.addProductModalLoading = true
            if (this.createProductMode === 'New Variant' && !this.existingProduct) {
              const product = this.$_.cloneDeep(this.product)
              delete product.variant_name
              delete product.pack
              const variants = []

              const variant = {
                name: '',
                sku: product.sku,
                initial_cost: 0,
                is_pack: false
              }

              if (this.withOption) {
                variant.options = this.$_.cloneDeep(product.options)
                variant.name += product.options.map(m => m.value).join(':')
                variant.sku += '-' + product.options.map(m => m.value.substring(0, 1)).join('-')

                for (let i = 0; i < product.options.length; i++) {
                  const opt = product.options[i]
                  delete opt.value
                }
              } else {
                variant.name = product.name
                delete product.options
              }

              variants.push(variant)

              if (this.withPack) {
                for (let i = 0; i < product.packs.length; i++) {
                  const option = product.packs[i]
                  const packVariant = {
                    name: option.label,
                    pack_size: option.size,
                    sku: variant.sku,
                    is_pack: true
                  }

                  variants.push(packVariant)
                }
              }

              delete product.packs

              this.$Sanitize(product)
              this.$Sanitize(variant)

              variant.prices = []
              variant.stocks = [
                { warehouse_id: 1, value: 0 }
              ]

              if (this.prices.buy_price) variant.prices.push({ price_list_id: 1, value: this.prices.buy_price })
              if (this.prices.wholesale_price) variant.prices.push({ price_list_id: 2, value: this.prices.wholesale_price })
              if (this.prices.retail_price) variant.prices.push({ price_list_id: 3, value: this.prices.retail_price })

              if (variant.prices.length === 0) delete variant.prices

              const products = {
                product: product,
                variants: variants
              }

              const pdService = new ProductService()
              await pdService.create(products)
              this.$message.success(`${this.product.name} successfully created`)
            } else if (this.createProductMode === 'New Variant' && this.existingProduct) {
              const product = this.$_.cloneDeep(this.product)
              delete product.pack

              const variant = {
                name: product.variant_name,
                sku: product.sku,
                initial_cost: 0,
                is_pack: false,
                options: product.options,
                stocks: [{
                  warehouse_id: 1,
                  value: 0
                }],
                prices: []
              }

              this.$Sanitize(variant)

              if (this.prices.buy_price) variant.prices.push({ price_list_id: 1, value: this.prices.buy_price })
              if (this.prices.wholesale_price) variant.prices.push({ price_list_id: 2, value: this.prices.wholesale_price })
              if (this.prices.retail_price) variant.prices.push({ price_list_id: 3, value: this.prices.retail_price })

              if (variant.prices.length === 0) delete variant.prices

              const pvService = new ProductVariantService(this.productId)
              await pvService.create(variant)

              this.$message.success(`${variant.name} successfully created`)
            } else if (this.createProductMode === 'New Pack') {
              const product = this.$_.cloneDeep(this.product)
              const pack = {
                name: product.pack.label,
                sku: this.selectedVariant.sku,
                pack_size: product.pack.size,
                is_pack: true,
                variant_id: this.variantId,
                prices: []
              }

              this.$Sanitize(pack)

              if (this.prices.buy_price) pack.prices.push({ price_list_id: 1, value: this.prices.buy_price })
              if (this.prices.wholesale_price) pack.prices.push({ price_list_id: 2, value: this.prices.wholesale_price })
              if (this.prices.retail_price) pack.prices.push({ price_list_id: 3, value: this.prices.retail_price })

              if (pack.prices.length === 0) delete pack.prices

              const pvService = new ProductVariantService(this.selectedVariant.product.id)
              await pvService.create(pack)
              this.$message.success(`${pack.name} successfully created`)
            } else {
              return
            }

            this.addProductModalVisible = false

            // reset
            this.selectedProduct = null
            this.selectedVariant = null
            this.productSearch = ''
            this.variantSearch = ''
            this.variantId = null
            this.productId = null
            this.product.name = null
            this.product.units = null
            this.product.sku = null
            this.product.options = [{ name: null, value: null }]
            this.product.packs = [{ label: null, size: null }]
            this.product.pack = { label: null, size: null }
            this.addProductPage = 1
            this.prices.buy_price = 0
            this.prices.retail_price = 0
            this.prices.wholesale_price = 0
            this.existingProduct = false
            this.autoGenerateSKU = true
            this.withOption = false
            this.withPack = false
            this.createProductMode = 'New Variant'
          } catch (error) {
            this.$Error(error)
          } finally {
            this.addProductModalLoading = false
          }
        })
        .catch(() => {})
    },
    nextPageProduct (page) {
      this.$refs.product.validate()
        .then(() => {
          this.addProductPage = page
        })
        .catch(() => {})
    },
    addOption () {
      this.product.options.push({ name: null, value: null })
    },
    removeOption () {
      this.product.options.pop()
    },
    addPack () {
      this.product.packs.push({ label: null, size: null })
    },
    removePack () {
      this.product.packs.pop()
    },
    async searchProduct (query) {
      try {
        const filter = {}

        if (query && query !== '') {
          filter.q = {
            like: query
          }
        } else {
          delete filter.q
        }

        const pdService = new ProductService()
        const result = await pdService.list(null, null, filter)
        this.products = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async selectProduct (id) {
      try {
        const pdService = new ProductService(id)
        const result = await pdService.get()
        this.selectedProduct = result.data
        this.product.name = result.data.name

        this.product.options = []
        for (let i = 0; i < this.selectedProduct.options.length; i++) {
          const option = this.selectedProduct.options[i]
          this.product.options.push({
            option_id: option.id,
            value: ''
          })
        }
      } catch (error) {
        this.$Error(error)
      }
    },
    selectVariant (id) {
      this.selectedVariant = this.variants.find(v => v.id === id)
      this.product.name = this.selectedVariant.product.name + ' - ' + this.selectedVariant.name
    },
    removeSelectedProduct () {
      this.selectedProduct = null
      this.productSearch = null
      this.productId = null
      this.product.name = null
      this.product.sku = null
      this.product.options = [{
        name: null,
        value: null
      }]
    },
    removeSelectedVariant () {
      this.selectedVariant = null
      this.variantSearch = null
      this.variantId = null
      this.product.name = null
      this.product.sku = null
    },
    generateSKU () {
      this.product.variant_name = this.product.options.map(s => s.value).join(':')
      this.product.sku = this.selectedProduct.sku + '-' + this.product.options.map(s => s.value.substring(0, 1)).join('-')
    },

    // supplier
    addSupplier () {
      this.$refs.supplier.validate()
        .then(async () => {
          try {
            this.addSupplierModalLoading = true
            const supplier = this.$_.cloneDeep(this.supplier)

            this.$Sanitize(supplier)
            this.$Sanitize(supplier.address[0])

            const cmService = new CompanyService()
            await cmService.create(supplier)

            this.$message.success(`${supplier.name} successfully created`)
            this.$ClearField('supplier')

            this.addSupplierModalVisible = false
            await this.getCompanies()
          } catch (error) {
            this.$Error(error)
          } finally {
            this.addSupplierModalLoading = false
          }
        })
        .catch(() => {})
    },

    // search
    async searchSupplier (query) {
      if (query !== '') {
        this.supplierFilter.q = {
          like: query
        }
      } else delete this.supplierFilter.q

      await this.getCompanies()
    },
    async searchVariant (query) {
      try {
        if (query !== '') {
          this.variantFilter.q = {
            like: query
          }
        } else delete this.variantFilter.q

        await this.getVariants()
      } catch (error) {
        this.$Error(error)
      }
    },

    //
    changeSelectedSupplier (id) {
      const name = this.suppliers.find(sup => sup.id === id).name
      this.selectedSupplier.name = name
      this.getCompanyAddresses()
    },
    removeSelectedSupplier () {
      this.supplier_addresses = []
      this.selectedSupplier.name = null
      this.po.supplier_address_id = null
      this.po.supplier_id = null
      this.searchSupplier('')
    },
    changeSelectedItem (id, index) {
      const exist = this.po.items.filter(item => item.variant_id === id).length

      if (exist > 1) {
        this.$notify.error({
          title: 'Duplicate Entry',
          message: 'Item already exist!'
        })
        this.po.items[index].variant_id = null
        return
      }

      const item = this.variants.find(vr => vr.id === id)
      this.selectedItems[index] = item

      const price = item.prices.find(pl => pl.price_list_id === this.po.price_list_id)
      if (!price) this.po.items[index].cost = 0
      else this.po.items[index].cost = parseFloat(price.value)

      const stock = item.stocks[0] || { value: 0 }
      if (item.is_pack) {
        this.po.items[index].stock = parseFloat(stock.value) / parseFloat(item.pack_size)
      } else {
        this.po.items[index].stock = parseFloat(stock.value)
      }
    },
    async changeSelectedExistingItem () {
      const items = await this.getItems()

      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const vr = await this.getVariant(item.product.id, item.variant_id)

        this.addItem()
        this.selectedItems[i] = vr

        const stock = vr.stocks[0] || { value: 0 }
        if (vr.is_pack) {
          this.po.items[i].stock = parseFloat(stock.value) / parseFloat(vr.pack_size)
        } else {
          this.po.items[i].stock = parseFloat(stock.value)
        }

        this.po.items[i].id = item.id
        this.po.items[i].variant_id = item.variant_id
        this.po.items[i].cost = parseFloat(item.cost)
        this.po.items[i].quantity = parseFloat(item.quantity)
        this.po.items[i].tax = parseFloat(item.tax)
      }
    },

    // Service fetch
    async setPONumber () {
      try {
        const poService = new PurchaseOrderService()
        const result = await poService.getLastPO()
        let poNumber = result.data.po_number

        if (!poNumber) poNumber = 'PO000000'
        const number = parseInt(poNumber.substring(2)) + 1
        this.po.po_number = 'PO' + this.$Numeral(number).format('000000')
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    },
    async getCompanies () {
      try {
        const cmService = new CompanyService()
        const result = await cmService.list(null, { cus: ['id', 'name'] }, this.supplierFilter)
        this.suppliers = result.data.rows
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    },
    async getCompanyAddresses () {
      try {
        const caService = new CompanyAddressService(this.po.supplier_id)
        const result = await caService.list()
        this.supplier_addresses = result.data.rows
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    },
    async getPriceLists () {
      try {
        const plService = new PriceListService()
        const result = await plService.list(null, null, { 'pl.price_list_type': { '=': 0 } })
        this.priceLists = result.data.rows
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    },
    async getVariants () {
      try {
        const vrService = new VariantService()
        const result = await vrService.list(null, null, this.variantFilter)
        this.variants = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getVariant (productId, variantId) {
      try {
        const pvService = new ProductVariantService(productId, variantId)
        const result = await pvService.get()
        return result.data
      } catch (error) {
        this.$Error(error)
      }
    },
    async getPurchaseOrder () {
      try {
        this.loading = true

        const poService = new PurchaseOrderService(this.purchaseOrderId)
        const result = await poService.get()
        result.data.items = []

        this.po = result.data
        this.po.issue_date = this.$DateISOFormat(this.po.issue_date)
        this.po.stock_due = this.$DateISOFormat(this.po.stock_due)
        this.po.payment_due = this.$DateISOFormat(this.po.payment_due)

        await this.searchSupplier(this.po.supplier.name)
        this.changeSelectedSupplier(this.po.supplier_id)

        delete this.po.bill_to
        delete this.po.ship_to
        delete this.po.supplier
        delete this.po.created_at
        delete this.po.updated_at

        this.changeSelectedExistingItem()
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      } finally {
        this.loading = false
      }
    },
    async getItems () {
      try {
        const piService = new PurchaseOrderItemService(this.purchaseOrderId)
        const result = await piService.list()
        return result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted () {
    this.loading = true
    this.mode = this.$route.meta.mode

    await this.getCompanies()
    await this.getPriceLists()
    await this.getVariants()

    if (this.mode === 'create') {
      this.title = 'New Purchase Order'
      this.subtitle = 'Create New Purchase Order'

      this.setPONumber()
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Stock Control', route: '' },
        { title: 'Purchase Order' },
        { title: 'Create' }
      ])

      this.loading = false
    } else {
      await this.getPurchaseOrder()

      this.title = this.po.po_number
      this.subtitle = 'Modify Purchase Order Details'

      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Stock Control', route: '' },
        { title: 'Purchase Order' },
        { title: this.po.po_number },
        { title: 'Update' }
      ])
    }
  }
}
</script>
